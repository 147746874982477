import {css, html, LitElement} from 'lit';
import '@polymer/iron-ajax';
import '@polymer/paper-button';

import Plyr from 'plyr';
import plyrStyles from 'plyr/dist/plyr.css?url';
import plyrIconUrl from 'plyr/dist/plyr.svg?url';

class VideoContentElement extends LitElement {
  static get properties() {
    return {
      video: {type: String},
      timestamps: {type: Object},
      request: {type: Object},
      preload: {type: String},
      plyr: {type: Object},
    };
  }

  constructor() {
    super();
    this.video = '';
    this.request = {};
    this.plyr = null;
    this.preload = 'none';
  }

  static styles = css`
    :host {
      display: block;
      text-align: center;
      padding-top: 2rem;
    }

    paper-button {
      background: #0c214c;
      color: #e7e9ef;
    }

    .row {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
  `;

  render() {
    return html`
      <link rel="stylesheet" href="${plyrStyles}" />

      <video preload="${this.preload}" src="${this.request.url ?? ''}" id="player" width="100%"></video>

      <iron-ajax
        id="ajax"
        url="/files/url"
        handle-as="json"
        .params="${{uuid: this.video}}"
        @response="${this._handleRequest}">
      </iron-ajax>

      ${Object.entries(this.timestamps).map(
        ([, value]) => html`
          <div class="row">
            <paper-button align="left" raised @click="${() => this.gotoTime(value.Tijd)}">
              ${value.Hoofdstuk}
            </paper-button>
          </div>`,
      )}
    `;
  }

  firstUpdated() {
    this.shadowRoot.getElementById('ajax').generateRequest();
  }

  startVideo() {
    if (this.plyr) {
      this.plyr.play();
    }
  }

  async _initializePlayer() {
    this.plyr = new Plyr(this.shadowRoot.getElementById('player'), {
      autoplay: true,
      iconUrl: plyrIconUrl,
      settings: ['speed'],
      keyboard: {
        global: true,
      },
      speed: {
        selected: 1,
        options: [0.8, 1, 1.2, 1.4, 1.6, 2],
      },
    });

    await new Promise((resolve) => {
      this.plyr.once('ready', () => {
        resolve();
      });
    });
  }

  gotoTime(time) {
    this.startVideo();
    if (this.plyr) {
      this.plyr.currentTime = Number(time);
    }
  }

  _handleRequest(event) {
    this.request = event.target.lastResponse;
    this.preload = 'auto';
    this._initializePlayer();
  }
}

customElements.define('video-content', VideoContentElement);
